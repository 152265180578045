import axios from 'axios';

export const utils = {
    getAuth: async () => {
        let response = await axios({
            url: 'https://sckbnrylj0.execute-api.eu-central-1.amazonaws.com/production/createAcsToken?timestamp=' + new Date().getTime(),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(window.location)
        })
        if (response.status === 200) {
            return response.data;
        }
        throw new Error('failed to get access token');
    },

    retryAuth: async (jwt) => {
        let data = JSON.parse(JSON.stringify(window.location));
        data.search = '?jwt=' + jwt;
        data.href = '?jwt=' + jwt;

        let response = await axios({
            url: 'https://sckbnrylj0.execute-api.eu-central-1.amazonaws.com/production/createAcsToken?timestamp=' + new Date().getTime(),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
        if (response.status === 200) {
            return response.data;
        }
        throw new Error('failed to get access token');
    }
}